import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import PageNotFoundView from '@/views/404.view.vue';

const routes: Array<RouteRecordRaw> = [
	{
		path: '/checkin/form/:orderKey/:clientCode/:onsite',
		name: 'CheckinView',
		component: () => import(/* webpackChunkName: "checkin" */ '../views/checkin/checkin.view.vue'),
		props: true,
		children: [
			{
				name: 'AppointmentsView',
				path: '/checkin/form/:orderKey/:clientCode/:onsite/appointments',
				component: () => import(/* webpackChunkName: "checkin" */ '../views/checkin/appointments.view.vue'),
				props: true,
			},
			{
				name: 'AdultCheckin',
				path: '/checkin/form/:orderKey/:clientCode/:onsite/consent',
				component: () => import(/* webpackChunkName: "checkin" */ '../views/checkin/adult.view.vue'),
				props: true,
			},
			{
				name: 'MinorsCheckin',
				path: '/checkin/form/:orderKey/:clientCode/:onsite/minors',
				component: () => import(/* webpackChunkName: "checkin" */ '../views/checkin/minors.view.vue'),
			},
			{
				name: 'ActivitySelection',
				path: '/checkin/form/:orderKey/:clientCode/:onsite/activities',
				props: true,
				component: () => import(/* webpackChunkName: "checkin" */ '../views/checkin/activitySelection.view.vue'),
			},
			{
				name: 'WaiverForms',
				path: '/checkin/form/:orderKey/:clientCode/:onsite/waivers',
				component: () => import(/* webpackChunkName: "checkin" */ '../views/checkin/waivers.vue'),
			},
			{
				name: 'Finished',
				path: '/checkin/form/:orderKey/:clientCode/:onsite/complete',
				component: () => import(/* webpackChunkName: "checkin" */ '../views/checkin/finished.view.vue'),
			},
			{
				name: 'Completed',
				path: '/checkin/form/:orderKey/:clientCode/:onsite/edit',
				component: () => import(/* webpackChunkName: "checkin" */ '../views/checkin/completedWaivers.view.vue'),
			},
			{
				name: 'Expired',
				path: '/checkin/form/:orderKey/:clientCode/:onsite/expired',
				component: () => import(/* webpackChunkName: "expired" */ '../views/checkin/expiredOrder.view.vue'),
			},
		],
	},
	{
		path: '/checkin/form/:clientCode/:onsite',
		redirect: (to) => {
			return { name: 'CheckinView', params: { ...to.params, orderKey: 0 }, query: { ...to.query } };
		},
	},
	{
		path: '/digital-tickets',
		name: 'DigitalTicketsView',
		component: () => import(/* webpackChunkName: "tickets" */ '../views/tickets/digitalTickets.view.vue'),
	},
	{
		path: '/404',
		name: 'PageNotFound',
		component: PageNotFoundView,
	},
	{
		path: '/:pathMatch(.*)',
		component: PageNotFoundView,
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

router.beforeEach((to, from, next) => {
	if (to.hash) return { ...to, hash: '' }; // remove hash

	if (to.name == 'AdultCheckin' || to.name == 'AppointmentsView') {
		document.dispatchEvent(new Event('showTranslator'));
	} else {
		document.dispatchEvent(new Event('hideTranslator'));
	}

	next();
});

export default router;
