import { v4 as uuidv4 } from 'uuid';

class uuidProxy {
	/**
	 * Returns the v4 uuid guid.
	 */
	v4(): string {
		return uuidv4();
	}
}

export const uuid = new uuidProxy();
