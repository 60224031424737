
import { Options, Vue } from 'vue-class-component';
import { kioskModule } from './store/kiosk.vuex-module';

@Options({
	name: 'App',
	components: {},
})
export default class App extends Vue {
	created() {
		document.addEventListener('langChange', () => this.filterLanguages());
	}

	filterLanguages() {
		const supportedLangs = kioskModule.client?.Languages.map((l) => l.LanguageKey);
		if (supportedLangs?.length) {
			const langToRemove: HTMLAnchorElement[] = [];
			const ljs = document.getElementById('localize-widget-langs');
			const trLangOpts = ljs?.getElementsByTagName('a');
			for (let langLink of trLangOpts ?? []) {
				const key = langLink.outerHTML.slice(34, langLink.outerHTML.indexOf('>') - 4);
				const langKey = kioskModule.langLookup(key);
				if (!supportedLangs.includes(langKey)) {
					langToRemove.push(langLink);
				}
			}

			for (let lang of langToRemove) {
				lang.remove();
			}
		}
	}
}
